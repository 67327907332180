import { Grid } from '@material-ui/core';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import React from 'react';
import { General, Hosting } from './components';

interface IProps {
  projectLearner: IProjectLearner;
  onSave: (projectLearner: IProjectLearner) => void;
}

const Details: React.FC<IProps> = (props) => {
  const { projectLearner, onSave } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <General projectLearner={projectLearner} onSave={onSave} />
      </Grid>
      <Grid item xs={4}>
        <Hosting projectLearner={projectLearner} onSave={onSave} />
      </Grid>
    </Grid>
  );
};

export default Details;
