import { IProject } from 'app/models/responses/IProject';
import { useCallback, useState, useEffect, ChangeEvent, Fragment, createRef } from 'react';
import axios from 'utils/axios';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useSnackbar } from 'notistack';
import { ExceptionHandler, Scrollbar } from 'components';
import buildQuery from 'odata-query';
import useMounted from 'hooks/useMounted';
import { rootConfig } from 'config';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Theme,
  useTheme,
  CardHeader,
  Divider,
  Box,
  Typography,
} from '@material-ui/core';
import { CourseType } from 'app/enums/CourseType';
import { ISkillsProgrammeRevisionUnit } from 'app/models/responses/ISkillsProgrammeRevisionUnit';
import { EntityContext } from 'app/enums/EntityContext';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ProjectEventType } from 'app/enums/ProjectEventType';
import usePrivateAppConfig from 'hooks/usePrivateAppConfig';
import { Link as RouterLink } from 'react-router-dom';
import { ProjectPlanEventPosition } from 'app/enums/ProjectPlanEventPosition';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  borderlessCell: {
    borderBottom: 'none',
  },
  dividerCell: {
    borderBottom: 'none',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  mainHeader: {},
  sectionHeader: {
    backgroundColor: theme.palette.primary.main,
    '& *': {
      color: '#fff',
    },
  },
  actionGrid: {
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  project: IProject;
}

const ProjectPlan: React.FC<IProps> = (props) => {
  const { project } = props;
  const classes = useStyles();
  const mounted = useMounted();
  const theme = useTheme();
  const pdfRef = createRef();
  const { privateAppConfig } = usePrivateAppConfig();
  const { enqueueSnackbar } = useSnackbar();
  const [learnershipSections, setLearnershipSections] = useState<ILearnershipRevisionSection[]>([]);
  const [skillsProgrammeUnitStandards, setSkillsProgrammeUnitStandards] = useState<
    ISkillsProgrammeRevisionUnit[]
  >([]);
  const [trainingSessions, setTrainingSessions] = useState<IProjectEvent[]>([]);
  const [events, setEvents] = useState<IProjectEvent[]>([]);

  const getTrainingSessions = useCallback(async () => {
    try {
      const expand = ['projectEventType'];
      const filter = { projectId: project.id, projectEventTypeId: 1 };
      const orderBy = 'start asc';
      const queryString = buildQuery({ filter, orderBy, expand });
      const response = await axios.get(`${rootConfig.odataRoute}/projectEvents${queryString}`);
      if (mounted.current) {
        setTrainingSessions(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, project.courseRevisionId]);

  const getEvents = useCallback(async () => {
    try {
      const expand = ['projectEventType'];

      const filter = {
        projectId: project.id,
        'projectEventType/displayOnProjectPlan': true,
      };
      const orderBy = 'start asc';
      const queryString = buildQuery({ filter, orderBy, expand });
      const response = await axios.get(`${rootConfig.odataRoute}/projectEvents${queryString}`);
      if (mounted.current) {
        setEvents(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, project.id]);

  const getSections = useCallback(async () => {
    try {
      const expand = { learnershipRevisionUnits: { expand: 'nqfLevel' } };
      const filter = { learnershipRevisionId: project.courseRevisionId };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(
        `${rootConfig.odataRoute}/learnershipRevisionSections${queryString}`,
      );
      if (mounted.current) {
        setLearnershipSections(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, project.courseRevisionId]);

  const getUnitStandards = useCallback(async () => {
    try {
      const filter = { skillsProgrammeRevisionId: project.courseRevisionId };
      const expand = ['nqfLevel'];
      const queryString = buildQuery({ filter, expand });
      const response = await axios.get(
        `${rootConfig.odataRoute}/skillsProgrammeRevisionUnits${queryString}`,
      );
      if (mounted.current) {
        setSkillsProgrammeUnitStandards(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, project.courseRevisionId]);

  useEffect(() => {
    if (project.courseRevision?.course?.type === CourseType[CourseType.Learnership]) {
      getSections();
    } else {
      getUnitStandards();
    }
    getTrainingSessions();
    getEvents();
  }, [getSections, getUnitStandards, getTrainingSessions, getEvents]);

  return (
    <>
      <Grid container spacing={2} className={classes.actionGrid}>
        {project.courseRevision?.course?.type === CourseType[CourseType.Learnership] &&
          privateAppConfig.learnershipProjectPlanReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.learnershipProjectPlanReport!
                  .name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
        {project.courseRevision?.course?.type === CourseType[CourseType.SkillsProgramme] &&
          privateAppConfig.skillsProgrammeProjectPlanReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.skillsProgrammeProjectPlanReport!
                  .name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
        {project.courseRevision?.course?.type === CourseType[CourseType.ShortCourse] &&
          privateAppConfig.shortCourseProjectPlanReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.shortCourseProjectPlanReport!
                  .name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
      </Grid>
      <Card>
        <Scrollbar>
          <Table innerRef={pdfRef} id='project-plan-table'>
            <TableHead>
              <TableRow className={classes.mainHeader}>
                <TableCell className={classes.borderlessCell}>Type</TableCell>
                <TableCell className={classes.borderlessCell}>Unit Standard</TableCell>
                <TableCell className={classes.borderlessCell}>LP</TableCell>
                <TableCell className={classes.borderlessCell}>NQF</TableCell>
                <TableCell className={classes.borderlessCell}>Credits</TableCell>
                <TableCell className={classes.borderlessCell}>Training Sessions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events
                .filter(
                  (e) =>
                    !e.parentContext &&
                    e.projectEventTypeId !== 1 &&
                    e.projectEventType!.projectPlanPosition ===
                      ProjectPlanEventPosition[ProjectPlanEventPosition.Start],
                )
                .map((event) => (
                  <TableRow
                    key={event.id}
                    selected
                    style={
                      event.projectEventType?.color
                        ? { backgroundColor: event.projectEventType?.color }
                        : undefined
                    }
                  >
                    <TableCell
                      colSpan={5}
                      style={{
                        borderBottom: 'none',
                        color: event.projectEventType?.color
                          ? '#FFFFFF'
                          : theme.palette.text.primary,
                      }}
                    >
                      {event.title || event.projectEventType?.title}
                    </TableCell>
                    <TableCell
                      className={classes.dividerCell}
                      style={{
                        color: event.projectEventType?.color
                          ? '#FFFFFF'
                          : theme.palette.text.primary,
                      }}
                    >
                      {moment(event.start!).format('DD MMMM YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              {project.courseRevision?.course?.type === CourseType[CourseType.Learnership] ? (
                <Fragment>
                  {learnershipSections.map((section) => (
                    <Fragment key={section.id}>
                      <TableRow className={classes.sectionHeader}>
                        <TableCell
                          className={classes.borderlessCell}
                          variant='head'
                          align='center'
                          colSpan={5}
                        >
                          {section.name}
                        </TableCell>
                        <TableCell
                          className={classes.dividerCell}
                          variant='head'
                          align='center'
                          colSpan={1}
                        >
                          {section.isSelfStudy ? (
                            'Self-Study'
                          ) : (
                            <>
                              {section.duration} {section.durationUnit}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      {events
                        .filter(
                          (e) =>
                            e.parentContext ===
                              EntityContext[EntityContext.LearnershipRevisionSection] &&
                            e.parentContextId === section.id &&
                            e.projectEventTypeId !== 1 &&
                            e.projectEventType!.projectPlanPosition ===
                              ProjectPlanEventPosition[ProjectPlanEventPosition.Start],
                        )
                        .map((event) => (
                          <TableRow
                            key={event.id}
                            selected
                            style={
                              event.projectEventType?.color
                                ? { backgroundColor: event.projectEventType?.color }
                                : undefined
                            }
                          >
                            <TableCell
                              colSpan={5}
                              style={{
                                borderBottom: 'none',
                                color: event.projectEventType?.color
                                  ? '#FFFFFF'
                                  : theme.palette.text.primary,
                              }}
                            >
                              {event.title || event.projectEventType?.title}
                            </TableCell>
                            <TableCell
                              className={classes.dividerCell}
                              style={{
                                color: event.projectEventType?.color
                                  ? '#FFFFFF'
                                  : theme.palette.text.primary,
                              }}
                            >
                              {moment(event.start!).format('DD MMMM YYYY')}
                            </TableCell>
                          </TableRow>
                        ))}
                      {section!.learnershipRevisionUnits!.map((unitStandard, index) => (
                        <TableRow key={unitStandard.id}>
                          <TableCell className={classes.borderlessCell}>
                            {unitStandard.saqaUnitStandardType}
                          </TableCell>
                          <TableCell className={classes.borderlessCell}>
                            {unitStandard.code}
                          </TableCell>
                          <TableCell className={classes.borderlessCell}>
                            {unitStandard.title}
                          </TableCell>
                          <TableCell className={classes.borderlessCell}>
                            {unitStandard.nqfLevel?.name}
                          </TableCell>
                          <TableCell className={classes.borderlessCell}>
                            {unitStandard.creditTotal}
                          </TableCell>
                          {unitStandard === section!.learnershipRevisionUnits![0] && (
                            <TableCell
                              className={classes.dividerCell}
                              rowSpan={section!.learnershipRevisionUnits!.length}
                              align='center'
                            >
                              {trainingSessions.filter(
                                (e) =>
                                  e.parentContext ===
                                    EntityContext[EntityContext.LearnershipRevisionSection] &&
                                  e.parentContextId === section.id,
                              ).length > 0
                                ? trainingSessions
                                    .filter(
                                      (e) =>
                                        e.parentContext ===
                                          EntityContext[EntityContext.LearnershipRevisionSection] &&
                                        e.parentContextId === section.id,
                                    )
                                    .map((trainingSession) => (
                                      <Typography
                                        variant='body2'
                                        style={{
                                          fontWeight: theme.typography.fontWeightMedium,
                                        }}
                                      >
                                        {moment(trainingSession.start!).format('DD MMMM YYYY')}
                                      </Typography>
                                    ))
                                : '-'}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                      {events
                        .filter(
                          (e) =>
                            e.parentContext ===
                              EntityContext[EntityContext.LearnershipRevisionSection] &&
                            e.parentContextId === section.id &&
                            e.projectEventTypeId !== 1 &&
                            e.projectEventType!.projectPlanPosition ===
                              ProjectPlanEventPosition[ProjectPlanEventPosition.End],
                        )
                        .map((event) => (
                          <TableRow
                            key={event.id}
                            selected
                            style={
                              event.projectEventType?.color
                                ? { backgroundColor: event.projectEventType?.color }
                                : undefined
                            }
                          >
                            <TableCell
                              colSpan={5}
                              style={{
                                borderBottom: 'none',
                                color: event.projectEventType?.color
                                  ? '#FFFFFF'
                                  : theme.palette.text.primary,
                              }}
                            >
                              {event.title || event.projectEventType?.title}
                            </TableCell>
                            <TableCell
                              className={classes.dividerCell}
                              style={{
                                color: event.projectEventType?.color
                                  ? '#FFFFFF'
                                  : theme.palette.text.primary,
                              }}
                            >
                              {moment(event.start!).format('DD MMMM YYYY')}
                            </TableCell>
                          </TableRow>
                        ))}
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  <TableRow className={classes.sectionHeader}>
                    <TableCell
                      className={classes.borderlessCell}
                      variant='head'
                      align='center'
                      colSpan={5}
                    >
                      {project.courseRevision?.course?.name}
                    </TableCell>
                    <TableCell
                      className={classes.dividerCell}
                      variant='head'
                      align='center'
                      colSpan={1}
                    >
                      {project.courseRevision?.durationInDays} Days
                    </TableCell>
                  </TableRow>
                  {skillsProgrammeUnitStandards!.map((unitStandard, index) => (
                    <TableRow key={unitStandard.id}>
                      <TableCell className={classes.borderlessCell}>N/A</TableCell>
                      <TableCell className={classes.borderlessCell}>{unitStandard.code}</TableCell>
                      <TableCell className={classes.borderlessCell}>{unitStandard.title}</TableCell>
                      <TableCell className={classes.borderlessCell}>
                        {unitStandard.nqfLevel?.name}
                      </TableCell>
                      <TableCell className={classes.borderlessCell}>
                        {unitStandard.creditTotal}
                      </TableCell>
                      {index === 0 && (
                        <TableCell
                          className={classes.dividerCell}
                          rowSpan={skillsProgrammeUnitStandards!.length}
                          align='center'
                        >
                          {trainingSessions.map((trainingSession) => (
                            <Typography
                              variant='body2'
                              style={{
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {moment(trainingSession.start!).format('DD MMMM YYYY')}
                            </Typography>
                          ))}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </Fragment>
              )}
              {events
                .filter(
                  (e) =>
                    !e.parentContext &&
                    e.projectEventTypeId !== 1 &&
                    e.projectEventType!.projectPlanPosition ===
                      ProjectPlanEventPosition[ProjectPlanEventPosition.End],
                )
                .map((event) => (
                  <TableRow
                    key={event.id}
                    selected
                    style={
                      event.projectEventType?.color
                        ? { backgroundColor: event.projectEventType?.color }
                        : undefined
                    }
                  >
                    <TableCell
                      colSpan={5}
                      style={{
                        borderBottom: 'none',
                        color: event.projectEventType?.color
                          ? '#FFFFFF'
                          : theme.palette.text.primary,
                      }}
                    >
                      {event.title || event.projectEventType?.title}
                    </TableCell>
                    <TableCell
                      className={classes.dividerCell}
                      style={{
                        color: event.projectEventType?.color
                          ? '#FFFFFF'
                          : theme.palette.text.primary,
                      }}
                    >
                      {moment(event.start!).format('DD MMMM YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );
};

export default ProjectPlan;
