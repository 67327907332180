import { colors } from '@material-ui/core';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const EMAILSTATUSCOLORS = {
  SUCCESS: colors.green[500],
  FAIL: colors.red[500],
  PENDING: colors.orange[500],
};

export const EXTRATIMEREQUESTCOLORS = {
  PENDING: colors.orange[500],
  APPROVED: colors.green[500],
  DECLINED: colors.red[500],
};

export const PROJECTSTATUSCOLORS = {
  GREEN: colors.green[500],
  AMBER: colors.amber[500],
  RED: colors.red[500],
};

export const TRAININGSESSIONATTENDANCESTATUSCOLORS = {
  ABSENT: colors.red[500],
  PRESENT: colors.green[500],
};

export const PROJECTPHASECOLORS = {
  WAITINGFORAPPROVALTOSTART: colors.amber[600],
  RECRUITMENT: colors.orange[600],
  HOSTING: colors.orange[800],
  PREPLANNING: colors.deepOrange[400],
  INDUCTIONLAUPLOADS: colors.deepOrange[600],
  TRAININGINPROGRESS: colors.indigo[600],
  TRAININGNOTINPROGRESS: colors.indigo[400],
  ASSESSMENTANDREMEDIATION: colors.purple[400],
  INTERNALMODERATION50: colors.purple[600],
  INTERNALMODERATION100: colors.deepPurple[400],
  FINALUPLOAD: colors.deepPurple[600],
  DEENROLLEARNER: colors.blueGrey[500],
  REQUESTFORLANUMBER: colors.pink[300],
  PREPFOREXIT: colors.pink[600],
  CLOSEOFFASIS: colors.pink[900],
  AWAITINGEXITDATE: colors.blue[400],
  VERIFIED: colors.blue[600],
  AWAITINGCERTSORSTATEMENTOFRESULTS: colors.teal[400],
  RECEIVEDCERTSORSTATEMENTOFRESULTS: colors.teal[600],
  DELIVEREDCERTSORSTATEMENTOFRESULTS: colors.green[400],
};

export const CURRENCYSYMBOL = {
  ZAR: 'R',
  USD: '$',
};

export const PROJECTEVENTTYPECOLORS = {
  TRAININGSESSION: colors.blue[500],
  POESUBMISSION: '#58585B',
  FEEDBACKANDREMEDIATION: '#898B8E',
  QUARTERLYFEEDBACKSESSION: '#3E87CB',
};

export const COURSEOUTCOMELEGEND = {
  COMPETENT: 'C',
  NOTYETCOMPETENT: 'NYC',
};

export const COURSESUBMISSIONLEGEND = {
  SUBMITTED: 'S',
  NOTSUBMITTED: 'NS',
};

export const ATTENDANCESTATUSLEGEND = {
  ABSENT: 'A',
  PRESENT: 'P',
};
