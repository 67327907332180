import { UserType } from 'app/enums/UserType';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import { PermissionClaim } from 'app/types/auth';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'utils/axios';

interface IAuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: IApplicationUser | null;
  permissions: PermissionClaim[];
}

const initialState: IAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: [],
};

const handlers: any = {
  SETUSER: (state: IAuthState, action: any) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  INITIALIZE: (state: IAuthState, action: any) => {
    const { isAuthenticated, user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions,
    };
  },
  LOGIN: (state: IAuthState, action: any) => {
    const { user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions,
    };
  },
  LOGOUT: (state: IAuthState) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    permissions: [],
  }),
};

const reducer = (state: IAuthState, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: (idOrEmail: string, password: string, userType: keyof typeof UserType) =>
    Promise.resolve(),
  logout: () => Promise.resolve(),
  setUser: (user: IApplicationUser) => Promise.resolve(),
});

export const AuthProvider = (props: IProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      try {
        setAxiosInterceptors();
        const accessToken = window.localStorage.getItem('accessToken');

        if (isValidToken(accessToken)) {
          setSession(accessToken);
          const res = await axios.get<IApplicationUser>(`${rootConfig.odataRoute}/users/me()`);
          const resPermissions = await axios.get<IODataResponse<PermissionClaim[]>>(
            `${rootConfig.odataRoute}/users(${res.data.id})/getPermissions()`,
          );
          const user = res.data;
          const permissions = resPermissions.data.value || [];
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              permissions,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            permissions: [],
          },
        });
      }
    };

    initialize(); // eslint-disable-next-line
  }, []);

  const setAxiosInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }

        return Promise.reject(error);
      },
    );
  };

  const login = async (idOrEmail: string, password: string, userType: keyof typeof UserType) => {
    try {
      console.log(userType);
      const res = await axios.post<any>(`${rootConfig.odataRoute}/users/login`, {
        idOrEmail,
        password,
        userType,
      });
      const accessToken = res.data.token;
      if (isValidToken(accessToken)) {
        setSession(accessToken);
        const res = await axios.get<IApplicationUser>(`${rootConfig.odataRoute}/users/me()`);
        const resPermissions = await axios.get<IODataResponse<PermissionClaim[]>>(
          `${rootConfig.odataRoute}/users(${res.data.id})/getPermissions()`,
        );
        const user = res.data;
        const permissions = resPermissions.data.value || [];
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            permissions,
          },
        });
      } else {
        setSession(null);
      }
    } catch (err) {
      enqueueSnackbar(<ExceptionHandler exception={err} />, { variant: 'error' });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const isValidToken = (accessToken: string | null | undefined) => {
    if (!accessToken) {
      return false;
    }

    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  const setSession = (accessToken: string | null | undefined) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const setUser = (user: IApplicationUser) => {
    dispatch({
      type: 'SETUSER',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface IProps {
  children: React.ReactNode;
}

export default AuthContext;
