import React, { useEffect, useRef, useState } from 'react';
import { Box, LinearProgress, Link } from '@material-ui/core';
import { ODataGrid, SelectOneAsync } from 'components/_dashboard';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { IProject } from 'app/models/responses/IProject';
import { ILearner } from 'app/models/responses/ILearner';
import { IMentor } from 'app/models/responses/IMentor';
import { IHostSite } from 'app/models/responses/IHostSite';
import { ProjectLearnerStatus } from 'app/enums/ProjectLearnerStatus';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';
import { IClient } from 'app/models/responses/IClient';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CachedIcon from '@material-ui/icons/Cached';
import { BulkEnrolment, LearnerEdit, LearnerReplacement } from './components';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { IProjectLearnerReplacement } from 'app/models/entities/IProjectLearnerReplacement';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';

interface IProps {
  project: IProject;
}

const Enrolments: React.FC<IProps> = (props) => {
  const { project } = props;

  const { enqueueSnackbar } = useSnackbar();

  const tableRef: any = useRef();
  const [learnerLookup, setLearnerLookup] = useState<any>(undefined);
  const [hostSiteLookup, setHostSiteLookup] = useState<any>(undefined);
  const [clientLookup, setClientLookup] = useState<any>(undefined);
  const [mentorLookup, setMentorLookup] = useState<any>(undefined);
  const [projectLearnerReplacements, setProjectLearnerReplacements] = useState<
    IProjectLearnerReplacement[] | null
  >(null);
  const [projectLearnerIdToEdit, setProjectLearnerIdToEdit] = useState<number | null>(null);
  const [isBulkEnrolmentOpen, setBulkEnrolmentOpen] = useState(false);
  const [isLearnerReplacementOpen, setLearnerReplacementOpen] = useState(false);
  const [isLearnerEditOpen, setLearnerEditOpen] = useState(false);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const filter = { projectId: project.id };
        const queryString = buildQuery({ filter });
        const response = await axios.get(
          `${rootConfig.odataRoute}/projectLearnerReplacements${queryString}`,
        );
        if (mounted) {
          setProjectLearnerReplacements(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const filter = { isEnrolled: true };
        const select = ['id', 'idNumber', 'fullName', 'email'];
        const queryString = buildQuery({ select, filter });
        const response = await axios.get(`${rootConfig.odataRoute}/learners${queryString}`);
        if (mounted) {
          const learnerLookups: any = {};
          response.data.value.forEach((learner: ILearner) => {
            learnerLookups[
              learner.id!
            ] = `${learner.fullName} | ${learner.idNumber} | ${learner.email}`;
          });
          setLearnerLookup(learnerLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const select = ['id', 'name'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/clients${queryString}`);
        if (mounted) {
          const clientLookups: any = {};
          response.data.value.forEach((client: IClient) => {
            clientLookups[client.id!] = `${client.name}`;
          });
          setClientLookup(clientLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const select = ['id', 'userName', 'fullName'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/mentors${queryString}`);
        if (mounted) {
          const mentorLookups: any = {};
          response.data.value.forEach((mentor: IMentor) => {
            mentorLookups[mentor.id!] = `${mentor.userName} | ${mentor.fullName}`;
          });
          setMentorLookup(mentorLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const select = ['id', 'name'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/hostSites${queryString}`);
        if (mounted) {
          const hostSiteLookups: any = {};
          response.data.value.forEach((hostSite: IHostSite) => {
            hostSiteLookups[hostSite.id!] = `${hostSite.name}`;
          });
          setHostSiteLookup(hostSiteLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  const handleLearnerEditClose = () => {
    setLearnerEditOpen(false);
    setProjectLearnerIdToEdit(null);
  };

  const handleLearnerReplacementClose = () => {
    setLearnerReplacementOpen(false);
    setProjectLearnerIdToEdit(null);
  };

  const handleLearnerEditOpen = (projectLearnerId: number) => {
    setProjectLearnerIdToEdit(projectLearnerId);
    setLearnerEditOpen(true);
  };

  const handleLearnerReplacementOpen = (projectLearnerId: number) => {
    setProjectLearnerIdToEdit(projectLearnerId);
    setLearnerReplacementOpen(true);
  };

  if (
    !learnerLookup ||
    !mentorLookup ||
    !hostSiteLookup ||
    !clientLookup ||
    !projectLearnerReplacements
  ) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <ODataGrid<IProjectLearner>
        tableRef={tableRef}
        entityType='projectLearners'
        filter={{ projectId: project.id }}
        option={{
          grouping: true,
        }}
        actions={[
          {
            icon: CachedIcon,
            tooltip: 'Refresh',
            isFreeAction: true,
            onClick: () => tableRef.current.onQueryChange(),
          },
          {
            icon: GroupAddIcon,
            tooltip: 'Bulk Enrolment',
            isFreeAction: true,
            onClick: (event: any) => setBulkEnrolmentOpen(true),
          },
          (rowData: any) => ({
            icon: TransferWithinAStationIcon,
            tooltip: 'Replace',
            onClick: () => handleLearnerReplacementOpen(rowData.id),
            disabled: rowData.status !== ProjectLearnerStatus[ProjectLearnerStatus.Enrolled],
          }),
        ]}
        columns={[
          {
            field: 'id',
            hidden: true,
          },
          {
            field: 'learnerId',
            title: 'Learner',
            grouping: false,
            editable: 'onAdd',
            lookup: learnerLookup,
            validate: (rowData) =>
              !rowData.learnerId ? { isValid: false, helperText: 'Learner is required' } : true,
            editComponent: (props) => (
              <SelectOneAsync
                name='learnerId'
                entityId={props.value || null}
                entityType='learners'
                endPoint={`projects(${project.id!})/getAllowedLearners()`}
                labelFields={['fullName', 'idNumber', 'email']}
                filterFields={['email', 'idNumber', 'firstName', 'lastName']}
                onChange={(event: any, value: any) => props.onChange(value?.id)}
                label='Search learner'
                required
                error={props.error}
              />
            ),
            render: (rowData) => (
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => handleLearnerEditOpen(rowData.id!)}
                variant='subtitle2'
                color='textPrimary'
              >
                {learnerLookup[rowData.learnerId!]}
              </Link>
            ),
          },
          {
            field: 'clientId',
            title: 'Client',
            lookup: clientLookup,
            initialEditValue:
              project.projectClients && project.projectClients.length > 0
                ? project.projectClients[0].clientId
                : null,
            validate: (rowData) =>
              !rowData.clientId && (project.projectClients?.length ?? 0) > 1
                ? { isValid: false, helperText: 'Client is required' }
                : true,
            editComponent: (props) => (
              <SelectOneAsync
                name='clientId'
                entityId={props.value || null}
                entityType='clients'
                labelFields={['name']}
                filterFields={['name']}
                onChange={(event: any, value: any) => props.onChange(value?.id)}
                label='Search client'
                required
                error={props.error}
              />
            ),
          },
          {
            field: 'hostSiteId',
            title: 'Host Site',
            lookup: hostSiteLookup,
            editable: 'never',
          },
          {
            field: 'mentorId',
            title: 'Mentor',
            lookup: mentorLookup,
            editable: 'never',
          },
          {
            field: 'projectId',
            title: 'Project',
            initialEditValue: project.id,
            hidden: true,
          },
          {
            field: 'status',
            title: 'Status',
            lookup: odataLookupObjFromEnum(ProjectLearnerStatus),
            initialEditValue: ProjectLearnerStatus[ProjectLearnerStatus.Enrolled],
            editable: 'never',
          },
        ]}
        canDelete
        canAdd
      />
      {projectLearnerIdToEdit && (
        <>
          <LearnerEdit
            projectLearnerId={projectLearnerIdToEdit}
            onClose={handleLearnerEditClose}
            open={isLearnerEditOpen}
            onConfirm={() => tableRef.current.onQueryChange()}
          />
          <LearnerReplacement
            open={isLearnerReplacementOpen}
            projectLearnerId={projectLearnerIdToEdit}
            onClose={handleLearnerReplacementClose}
            project={project}
            onConfirm={() => tableRef.current.onQueryChange()}
          />
        </>
      )}
      <BulkEnrolment
        open={isBulkEnrolmentOpen}
        onClose={() => setBulkEnrolmentOpen(false)}
        project={project}
        onConfirm={() => tableRef.current.onQueryChange()}
      />
    </Box>
  );
};

export default Enrolments;
