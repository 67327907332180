import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import { ProjectLearnerStatus } from 'app/enums/ProjectLearnerStatus';
import { ProjectType } from 'app/enums/ProjectType';
import { IManualAudit } from 'app/models/responses/IManualAudit';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ExceptionHandler } from 'components';
import { SelectOneAsync, Tip } from 'components/_dashboard';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React from 'react';
import axios from 'utils/axios';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';
import * as Yup from 'yup';

interface IProps {
  projectLearner: IProjectLearner;
  onSave: (projectLearner: IProjectLearner) => void;
}

const General: React.FC<IProps> = (props) => {
  const { projectLearner, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        mentorId: projectLearner.mentorId,
        clientId: projectLearner.clientId,
        status: projectLearner.status,
        isStipendAdmin: projectLearner.isStipendAdmin || false,
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        clientId: Yup.number().required(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const updatedEntity: any = {
            ...projectLearner,
            ...values,
          };
          delete updatedEntity['submit'];
          const diff = jsonpatch.compare(projectLearner, updatedEntity); //would be post
          const expand = ['learner', 'mentor', 'project'];
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/projectLearners(${projectLearner.id})${queryString}`,
            diff,
          );
          const updatedProjectLearner: IProjectLearner = response.data;
          resetForm({
            values: {
              mentorId: updatedProjectLearner.mentorId,
              clientId: updatedProjectLearner.clientId,
              //hostSiteId: updatedProjectLearner.hostSiteId,
              status: updatedProjectLearner.status,
              isStipendAdmin: updatedProjectLearner.isStipendAdmin!,
              submit: null,
            },
          });
          onSave(updatedProjectLearner); //state
          enqueueSnackbar(`Successfully updated learner.`, { variant: 'success' });
          setStatus({ success: true });
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Card>
              <CardHeader title='General' />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Tip message='Available mentors will only update once you have set and saved the client and/or host site of this learner.' />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectOneAsync
                      disabled={projectLearner.project?.type == ProjectType[ProjectType.Client]}
                      name='clientId'
                      entityId={values.clientId || null}
                      required
                      error={!!errors.clientId}
                      entityType='clients'
                      labelFields={['name']}
                      filterFields={['name']}
                      onChange={(event: any, value: any) =>
                        setFieldValue('clientId', value?.id || null)
                      }
                      label='Client'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectOneAsync
                      name='mentorId'
                      entityId={values.mentorId || null}
                      entityType='mentors'
                      endPoint={`projectLearners(${projectLearner.id})/getAvailableMentors()`}
                      labelFields={['userName', 'fullName']}
                      filterFields={['userName', 'firstName', 'lastName']}
                      onChange={(event: any, value: any) =>
                        setFieldValue('mentorId', value?.id || null)
                      }
                      label='Mentor'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={values.status}
                        label='Status'
                        onChange={handleChange}
                        name='status'
                        variant='outlined'
                      >
                        {odataOptionsArrayFromEnum(ProjectLearnerStatus).map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ px: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isStipendAdmin}
                            color='secondary'
                            edge='start'
                            name='roundedCorners'
                            onChange={(event) =>
                              setFieldValue('isStipendAdmin', event.target.checked)
                            }
                          />
                        }
                        label={
                          <div>
                            <Typography variant='subtitle1'>Is Stipend Admin?</Typography>
                            <Typography color='textSecondary' variant='caption' component='p'>
                              Indicate whether or not stipend administration is required for this
                              learner.
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color='primary'
                  disabled={isSubmitting || !isValid || !dirty}
                  type='submit'
                  variant='contained'
                >
                  Save Changes
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default General;
