import { Suspense, lazy } from 'react';
import { AuthGuard, GuestGuard, LoadingScreen } from 'components';
import { DashboardLayout } from 'components/_dashboard';
import { RouteObject } from 'react-router-dom';
import Course from 'pages/_dashboard/_learn/Course';
import Certificates from 'pages/_dashboard/_learn/Certificates';
import Calendar from 'pages/_dashboard/_learn/Calendar';
import CourseHistory from 'pages/_dashboard/_learn/CourseHistory';
import ChangeDetails from 'pages/_dashboard/_learn/ChangeDetails';
import ProjectDashboard from 'pages/_dashboard/_client-portal/ProjectDashboard';
import RouterAccessControl from 'components/router-access-control';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('pages/_authentication/Login')));
const PasswordReset = Loadable(lazy(() => import('pages/_authentication/PasswordReset')));

// Dashboard pages

const Overview = Loadable(lazy(() => import('pages/_dashboard/Overview')));
const Changelog = Loadable(lazy(() => import('pages/_dashboard/Changelog')));
const Approvals = Loadable(lazy(() => import('pages/_dashboard/Approvals')));
const CandidateList = Loadable(lazy(() => import('pages/_dashboard/CandidateList')));
const ClientList = Loadable(lazy(() => import('pages/_dashboard/ClientList')));
const ClientDetails = Loadable(lazy(() => import('pages/_dashboard/ClientDetails')));
const ContactList = Loadable(lazy(() => import('pages/_dashboard/ContactList')));
const ContactDetails = Loadable(lazy(() => import('pages/_dashboard/ContactDetails')));
const VerificationList = Loadable(lazy(() => import('pages/_dashboard/VerificationList')));
const VerificationCreate = Loadable(lazy(() => import('pages/_dashboard/VerificationCreate')));
const VerificationDetails = Loadable(lazy(() => import('pages/_dashboard/VerificationDetails')));
const FacilitatorList = Loadable(lazy(() => import('pages/_dashboard/FacilitatorList')));
const FacilitatorDetails = Loadable(lazy(() => import('pages/_dashboard/FacilitatorDetails')));
const HostSiteList = Loadable(lazy(() => import('pages/_dashboard/HostSiteList')));
const HostSiteDetails = Loadable(lazy(() => import('pages/_dashboard/HostSiteDetails')));
const HostingRequestList = Loadable(lazy(() => import('pages/_dashboard/HostingRequestList')));
const HostingRequestCreate = Loadable(lazy(() => import('pages/_dashboard/HostingRequestCreate')));
const HostingRequestDetails = Loadable(
  lazy(() => import('pages/_dashboard/HostingRequestDetails')),
);
const RecruitmentRequestList = Loadable(
  lazy(() => import('pages/_dashboard/RecruitmentRequestList')),
);
const RecruitmentRequestCreate = Loadable(
  lazy(() => import('pages/_dashboard/RecruitmentRequestCreate')),
);
const RecruitmentRequestDetails = Loadable(
  lazy(() => import('pages/_dashboard/RecruitmentRequestDetails')),
);
const LearnerList = Loadable(lazy(() => import('pages/_dashboard/LearnerList')));
const LearnerDetails = Loadable(lazy(() => import('pages/_dashboard/LearnerDetails')));
const EmployeeList = Loadable(lazy(() => import('pages/_dashboard/EmployeeList')));
const MentorList = Loadable(lazy(() => import('pages/_dashboard/MentorList')));
const MentorDetails = Loadable(lazy(() => import('pages/_dashboard/MentorDetails')));
const ProjectList = Loadable(lazy(() => import('pages/_dashboard/ProjectList')));
const ProjectDetails = Loadable(lazy(() => import('pages/_dashboard/ProjectDetails')));
const ProjectCreate = Loadable(lazy(() => import('pages/_dashboard/ProjectCreate')));
const SetaList = Loadable(lazy(() => import('pages/_dashboard/SetaList')));
const SetaDetails = Loadable(lazy(() => import('pages/_dashboard/SetaDetails')));
const UserProfile = Loadable(lazy(() => import('pages/_dashboard/UserProfile')));

// Learner Dashboard Pages

const LearnerCourse = <Course />;
const LearnerCertificates = <Certificates />;
const LearnerCalendar = <Calendar />;
const LearnerCourseHistory = <CourseHistory />;
const LearnerChangeDetails = <ChangeDetails />;

// Client Dashboard Pages

const ClientProjectDashboard = <ProjectDashboard />;

// Communication pages
const Chat = Loadable(lazy(() => import('pages/_dashboard/_communication/Chat')));
const EmailList = Loadable(lazy(() => import('pages/_dashboard/_communication/EmailList')));

// Finance pages
const SalesItems = Loadable(lazy(() => import('pages/_dashboard/_finance/SalesItems')));
const CreditNotes = Loadable(lazy(() => import('pages/_dashboard/_finance/CreditNotes')));

// Integration pages
const ApiDocs = Loadable(lazy(() => import('pages/_dashboard/_integration/ApiDocs')));
const ApiKeyList = Loadable(lazy(() => import('pages/_dashboard/_integration/ApiKeyList')));

// Setups pages
const DefaultFolderList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/DefaultFolderList')),
);
const DefaultFolderImport = Loadable(
  lazy(() => import('pages/_dashboard/_setups/DefaultFolderImport')),
);
const BeeLevelList = Loadable(lazy(() => import('pages/_dashboard/_setups/BeeLevelList')));
const CountryList = Loadable(lazy(() => import('pages/_dashboard/_setups/CountryList')));
const CourseExemptionList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/CourseExemptionList')),
);
const DietaryPreferenceList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/DietaryPreferenceList')),
);
const DisabilityList = Loadable(lazy(() => import('pages/_dashboard/_setups/DisabilityList')));
const EthnicityList = Loadable(lazy(() => import('pages/_dashboard/_setups/EthnicityList')));
const FileTypeList = Loadable(lazy(() => import('pages/_dashboard/_setups/FileTypeList')));
const FileTypeImport = Loadable(lazy(() => import('pages/_dashboard/_setups/FileTypeImport')));
const GenderList = Loadable(lazy(() => import('pages/_dashboard/_setups/GenderList')));
const IndustryList = Loadable(lazy(() => import('pages/_dashboard/_setups/IndustryList')));
const LanguageList = Loadable(lazy(() => import('pages/_dashboard/_setups/LanguageList')));
const QualificationCategoryList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/QualificationCategoryList')),
);
const RegionList = Loadable(lazy(() => import('pages/_dashboard/_setups/RegionList')));
const SchoolQualificationList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/SchoolQualificationList')),
);
const SocioEconomicStatusList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/SocioEconomicStatusList')),
);
const TitleList = Loadable(lazy(() => import('pages/_dashboard/_setups/TitleList')));
const NqfLevelList = Loadable(lazy(() => import('pages/_dashboard/_setups/NqfLevelList')));
const LearnershipList = Loadable(lazy(() => import('pages/_dashboard/_setups/LearnershipList')));
const LearnershipImport = Loadable(
  lazy(() => import('pages/_dashboard/_setups/LearnershipImport')),
);
const LearnershipRevisionDetails = Loadable(
  lazy(() => import('pages/_dashboard/_setups/LearnershipRevisionDetails')),
);
const ProjectDeliveryMethodList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ProjectDeliveryMethodList')),
);
const ProjectFeedbackList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ProjectFeedbackList')),
);
const ProjectEventTypeDetails = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ProjectEventTypeDetails')),
);
const ProjectEventTypeList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ProjectEventTypeList')),
);
const ProjectPhaseList = Loadable(lazy(() => import('pages/_dashboard/_setups/ProjectPhaseList')));
const ProjectStatusList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ProjectStatusList')),
);
const ShortCourseList = Loadable(lazy(() => import('pages/_dashboard/_setups/ShortCourseList')));
const ShortCourseImport = Loadable(
  lazy(() => import('pages/_dashboard/_setups/ShortCourseImport')),
);
const SkillsProgrammeList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/SkillsProgrammeList')),
);
const SkillsProgrammeImport = Loadable(
  lazy(() => import('pages/_dashboard/_setups/SkillsProgrammeImport')),
);
const SkillsProgrammeRevisionDetails = Loadable(
  lazy(() => import('pages/_dashboard/_setups/SkillsProgrammeRevisionDetails')),
);
const VenueList = Loadable(lazy(() => import('pages/_dashboard/_setups/VenueList')));
const VenueDetails = Loadable(lazy(() => import('pages/_dashboard/_setups/VenueDetails')));
const VerificationStatusList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/VerificationStatusList')),
);
const HostingRequestStatusList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/HostingRequestStatusList')),
);
const RecruitmentRequestStatusList = Loadable(
  lazy(() => import('pages/_dashboard/_setups/RecruitmentRequestStatusList')),
);

// System pages
const ReportList = Loadable(lazy(() => import('pages/_dashboard/_system/ReportList')));
const ReportDesigner = Loadable(lazy(() => import('pages/_dashboard/_system/ReportDesigner')));
const ReportViewer = Loadable(lazy(() => import('pages/_dashboard/_system/ReportViewer')));
const System = Loadable(lazy(() => import('pages/_dashboard/System')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('pages/_errors/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('pages/_errors/NotFound')));
const ServerError = Loadable(lazy(() => import('pages/_errors/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'learn',
        children: [
          {
            path: 'calendar',
            element: LearnerCalendar,
          },
          {
            path: 'certificates',
            element: LearnerCertificates,
          },
          {
            path: 'course',
            children: [
              {
                path: ':id',
                element: LearnerCourse,
              },
            ],
          },
          {
            path: 'course-history',
            element: LearnerCourseHistory,
          },
          {
            path: 'change-details',
            element: LearnerChangeDetails,
          },
        ],
      },
      {
        path: 'client-portal',
        children: [
          {
            path: 'projects',
            children: [
              {
                path: ':id',
                element: <ProjectDashboard />,
              },
            ],
          },
        ],
      },
      {
        path: 'changelog',
        element: <Changelog />,
      },
      {
        path: 'recruitment-and-hosting',
        element: <RouterAccessControl requiredPermissions={['Manage.AdminPortal']} />,
        children: [
          {
            path: 'candidates',
            children: [
              {
                path: '',
                element: <CandidateList />,
              },
            ],
          },
          {
            path: 'host-sites',
            children: [
              {
                path: '',
                element: <HostSiteList />,
              },
              {
                path: ':id',
                element: <HostSiteDetails />,
              },
            ],
          },
          {
            path: 'hosting-requests',
            children: [
              {
                path: '',
                element: <HostingRequestList />,
              },
              {
                path: 'create',
                element: <HostingRequestCreate />,
              },
              {
                path: ':id',
                element: <HostingRequestDetails />,
              },
            ],
          },
          {
            path: 'recruitment-requests',
            children: [
              {
                path: '',
                element: <RecruitmentRequestList />,
              },
              {
                path: 'create',
                element: <RecruitmentRequestCreate />,
              },
              {
                path: ':id',
                element: <RecruitmentRequestDetails />,
              },
            ],
          },
        ],
      },
      {
        path: 'training',
        element: <RouterAccessControl requiredPermissions={['Manage.AdminPortal']} />,
        children: [
          {
            path: 'clients',
            children: [
              {
                path: '',
                element: <ClientList />,
              },
              {
                path: ':id',
                element: <ClientDetails />,
              },
            ],
          },
          {
            path: 'facilitators',
            children: [
              {
                path: '',
                element: <FacilitatorList />,
              },
              {
                path: ':id',
                element: <FacilitatorDetails />,
              },
            ],
          },

          {
            path: 'learners',
            children: [
              {
                path: '',
                element: <LearnerList />,
              },
              {
                path: ':id',
                element: <LearnerDetails />,
              },
            ],
          },
          {
            path: 'mentors',
            children: [
              {
                path: '',
                element: <MentorList />,
              },
              {
                path: ':id',
                element: <MentorDetails />,
              },
            ],
          },
          {
            path: 'contacts',
            children: [
              {
                path: '',
                element: <ContactList />,
              },
              {
                path: ':id',
                element: <ContactDetails />,
              },
            ],
          },
          {
            path: 'projects',
            children: [
              {
                path: '',
                element: <ProjectList />,
              },
              {
                path: ':id',
                element: <ProjectDetails />,
              },
              {
                path: 'create',
                element: <ProjectCreate />,
              },
            ],
          },
        ],
      },
      {
        path: 'quality-assurance',
        element: <RouterAccessControl requiredPermissions={['Manage.AdminPortal']} />,
        children: [
          {
            path: 'setas',
            children: [
              {
                path: '',
                element: <SetaList />,
              },
              {
                path: ':id',
                element: <SetaDetails />,
              },
            ],
          },
          {
            path: 'verifications',
            children: [
              {
                path: '',
                element: <VerificationList />,
              },
              {
                path: 'create',
                element: <VerificationCreate />,
              },
              {
                path: ':id',
                element: <VerificationDetails />,
              },
            ],
          },
        ],
      },
      {
        path: 'administration',
        element: <RouterAccessControl requiredPermissions={['Manage.AdminPortal']} />,
        children: [
          {
            path: 'approvals',
            children: [
              {
                path: '',
                element: <Approvals />,
              },
            ],
          },
        ],
      },
      {
        path: 'communication',
        children: [
          {
            path: 'chat',
            children: [
              {
                path: '',
                element: <Chat />,
              },
              {
                path: 'new',
                element: <Chat />,
              },
              {
                path: ':id',
                element: <Chat />,
              },
            ],
          },
          {
            path: 'emails',
            element: <RouterAccessControl requiredPermissions={['View.Communication']} />,
            children: [
              {
                path: '',
                element: <EmailList />,
              },
            ],
          },
        ],
      },

      {
        path: 'employees',
        element: <RouterAccessControl requiredPermissions={['Manage.Employees']} />,
        children: [
          {
            path: '',
            element: <EmployeeList />,
          },
        ],
      },
      {
        path: 'finance',
        element: <RouterAccessControl requiredPermissions={['Manage.System']} />,
        children: [
          {
            path: 'credit-notes',
            element: <CreditNotes />,
          },
          {
            path: 'sales-items',
            element: <SalesItems />,
          },
        ],
      },
      {
        path: 'integration',
        children: [
          {
            path: 'api-docs',
            element: <RouterAccessControl requiredPermissions={['View.ApiDocs']} />,
            children: [
              {
                path: '',
                element: <ApiDocs />,
              },
            ],
          },
          {
            path: 'api-keys',
            element: <RouterAccessControl requiredPermissions={['Manage.System']} />,
            children: [
              {
                path: '',
                element: <ApiKeyList />,
              },
            ],
          },
        ],
      },
      {
        path: 'reporting',
        element: <RouterAccessControl requiredPermissions={['Manage.Reporting']} />,
        children: [
          {
            path: '',
            element: <ReportList />,
          },
          {
            path: 'designer',
            children: [
              {
                path: ':report',
                element: <ReportDesigner />,
              },
            ],
          },
          {
            path: 'viewer',
            children: [
              {
                path: ':report',
                element: <ReportViewer />,
              },
            ],
          },
        ],
      },
      {
        path: 'setups',
        element: <RouterAccessControl requiredPermissions={['Manage.Setups']} />,
        children: [
          {
            path: 'bee-levels',
            element: <BeeLevelList />,
          },
          {
            path: 'countries',
            element: <CountryList />,
          },
          {
            path: 'course-exemptions',
            element: <CourseExemptionList />,
          },
          {
            path: 'dietary-preferences',
            element: <DietaryPreferenceList />,
          },
          {
            path: 'disabilities',
            element: <DisabilityList />,
          },
          {
            path: 'default-folders',
            children: [
              {
                path: '',
                element: <DefaultFolderList />,
              },
              {
                path: 'import',
                element: <DefaultFolderImport />,
              },
            ],
          },
          {
            path: 'ethnicities',
            element: <EthnicityList />,
          },
          {
            path: 'file-types',
            children: [
              {
                path: '',
                element: <FileTypeList />,
              },
              {
                path: 'import',
                element: <FileTypeImport />,
              },
            ],
          },
          {
            path: 'genders',
            element: <GenderList />,
          },
          {
            path: 'hosting-request-statuses',
            element: <HostingRequestStatusList />,
          },
          {
            path: 'industries',
            element: <IndustryList />,
          },
          {
            path: 'languages',
            element: <LanguageList />,
          },
          {
            path: 'learnerships',
            children: [
              {
                path: '',
                element: <LearnershipList />,
              },
              {
                path: 'import',
                element: <LearnershipImport />,
              },
            ],
          },
          {
            path: 'learnership-revisions',
            children: [
              {
                path: ':id',
                element: <LearnershipRevisionDetails />,
              },
            ],
          },
          {
            path: 'project-delivery-methods',
            element: <ProjectDeliveryMethodList />,
          },
          {
            path: 'project-event-types',
            children: [
              {
                path: '',
                element: <ProjectEventTypeList />,
              },
              {
                path: ':id',
                element: <ProjectEventTypeDetails />,
              },
            ],
          },
          {
            path: 'project-feedback',
            element: <ProjectFeedbackList />,
          },
          {
            path: 'project-phases',
            element: <ProjectPhaseList />,
          },
          {
            path: 'project-statuses',
            element: <ProjectStatusList />,
          },
          {
            path: 'qualification-categories',
            element: <QualificationCategoryList />,
          },
          {
            path: 'recruitment-request-statuses',
            element: <RecruitmentRequestStatusList />,
          },
          {
            path: 'regions',
            element: <RegionList />,
          },
          {
            path: 'school-qualifications',
            element: <SchoolQualificationList />,
          },

          {
            path: 'nqf-levels',
            element: <NqfLevelList />,
          },
          {
            path: 'short-courses',
            children: [
              {
                path: '',
                element: <ShortCourseList />,
              },
              {
                path: 'import',
                element: <ShortCourseImport />,
              },
            ],
          },
          {
            path: 'skills-programmes',
            children: [
              {
                path: '',
                element: <SkillsProgrammeList />,
              },
              {
                path: 'import',
                element: <SkillsProgrammeImport />,
              },
            ],
          },
          {
            path: 'skills-programme-revisions',
            children: [
              {
                path: ':id',
                element: <SkillsProgrammeRevisionDetails />,
              },
            ],
          },
          {
            path: 'socio-economic-statuses',
            element: <SocioEconomicStatusList />,
          },
          {
            path: 'titles',
            element: <TitleList />,
          },
          {
            path: 'venues',
            children: [
              {
                path: '',
                element: <VenueList />,
              },
              {
                path: ':id',
                element: <VenueDetails />,
              },
            ],
          },
          {
            path: 'verification-statuses',
            element: <VerificationStatusList />,
          },
        ],
      },
      {
        path: 'system',
        element: <RouterAccessControl requiredPermissions={['Manage.System']} />,
        children: [
          {
            path: '',
            element: <System />,
          },
        ],
      },
      {
        path: 'profile',
        element: <UserProfile />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
