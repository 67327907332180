import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import './index.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { AuthProvider } from 'contexts/JWTContext';
import { SettingsConsumer, SettingsProvider } from 'contexts/SettingsContext';
import store from 'store';
import { SnackbarProvider } from 'components';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from '@material-ui/core';
import { createCustomTheme } from 'theme';
import { rootConfig } from 'config';
import { PrivateAppConfigProvider } from 'contexts/PrivateAppConfigContext';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider theme={createCustomTheme(settings)}>
                <SnackbarProvider>
                  <BrowserRouter basename={rootConfig.basename}>
                    <AuthProvider>
                      <PrivateAppConfigProvider>
                        <App />
                      </PrivateAppConfigProvider>
                    </AuthProvider>
                  </BrowserRouter>
                </SnackbarProvider>
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
