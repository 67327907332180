import { ExceptionHandler, LoadingScreen } from 'components';
import useSettings from 'hooks/useSettings';
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useState } from 'react';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import { IPrivateAppConfig } from 'app/models/responses/IPrivateAppConfig';

const initialConfig: IPrivateAppConfig = {};

const PrivateAppConfigContext = createContext({
  privateAppConfig: initialConfig,
  savePrivateAppConfig: (privateAppConfig: any) => {},
});

interface IProps {
  children: React.ReactNode;
}

export const PrivateAppConfigProvider: React.FC<IProps> = (props) => {
  const { children } = props;
  //const { enqueueSnackbar } = useSnackbar();
  const [privateAppConfig, setPrivateAppConfig] = useState(initialConfig);
  const { settings } = useSettings();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${rootConfig.odataRoute}/appConfigs/getPrivate()`);
        savePrivateAppConfig(response.data);
      } catch (error) {
        //enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
        console.error(error);
      } finally {
        if (process.env.NODE_ENV === 'production') {
          setTimeout(function () {
            setLoading(false);
          }, 500);
        } else {
          setLoading(false);
        }
      }
    })();
  }, []);

  const savePrivateAppConfig: any = (updatedConfig: any) => {
    setPrivateAppConfig(updatedConfig);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <PrivateAppConfigContext.Provider
      value={{
        privateAppConfig,
        savePrivateAppConfig,
      }}
    >
      {children}
    </PrivateAppConfigContext.Provider>
  );
};

export const PrivateAppConfigConsumer = PrivateAppConfigContext.Consumer;

export default PrivateAppConfigContext;
