import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { IContact } from 'app/models/responses/IContact';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SelectOneAsync } from 'components/_dashboard';
import { IClient } from 'app/models/responses/IClient';
import { IHostSite } from 'app/models/responses/IHostSite';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import { ISeta } from 'app/models/responses/ISeta';

interface IProps {
  open: boolean;
  onClose: () => void;
  onAdd: (contactId: number) => void;
  parentEntityType: 'clients' | 'hostSites' | 'setas';
  parentEntity: IHostSite | IClient;
}

const ContactPersonAdd: React.FC<IProps> = (props: IProps) => {
  const { open, onClose, onAdd, parentEntityType, parentEntity } = props;
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null);

  const handleClose = () => {
    onClose();
  };

  const assignContact = async (contactId: number | null | undefined, isPrimaryContact: boolean) => {
    if (!contactId) return {};

    const updatedContacts = parentEntity.contactPersons ? [...parentEntity.contactPersons] : [];
    const updatedEntity: IHostSite | IClient | ISeta = {
      ...parentEntity,
      primaryContactId: isPrimaryContact ? contactId : parentEntity.primaryContactId,
      contactPersons: updatedContacts.concat({ contactId: contactId }),
    };

    const diff = jsonpatch.compare(parentEntity, updatedEntity);
    const queryString = buildQuery({});
    await axios.patch(
      `${rootConfig.odataRoute}/${parentEntityType}(${parentEntity.id})${queryString}`,
      diff,
    );
    onAdd(contactId);
  };

  const handleContactChange = async (id: any) => {
    try {
      setSelectedContactId(id);
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          jobTitle: '',
          department: '',
          dateOfBirth: null,
          titleId: null,
          cellNumber: '',
          homeNumber: '',
          workNumber: '',
          emailAddress: '',
          isPrimary: false,
          submit: null,
        }}
        validateOnChange
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Not a valid email').optional(),
          emailAddress: Yup.string().email('Not a valid email').optional(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          try {
            setSubmitting(true);
            if (selectedContactId) {
              //allocate contact
              await assignContact(selectedContactId, values.isPrimary);
            } else {
              //create new contact
              const newEntity: any = {
                ...values,
                contactInfo: {
                  ...values,
                },
              };
              const select = ['id'];
              const queryString = buildQuery({ select });
              const response = await axios.post<IContact>(
                `${rootConfig.odataRoute}/contacts${queryString}`,
                newEntity,
              );
              await assignContact(response.data.id, values.isPrimary);
            }

            resetForm();
            setSelectedContactId(null);
            enqueueSnackbar(`Successfully added contact.`, { variant: 'success' });
            setStatus({ success: true });
          } catch (error) {
            enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
            setStatus({ success: false });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit} {...props}>
            <DialogTitle>Add Contact Person</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectOneAsync
                    disabled={dirty && !selectedContactId}
                    name='contactId'
                    entityId={selectedContactId || null}
                    entityType='contacts'
                    labelFields={['fullName', 'email', 'userName']}
                    filterFields={['firstName', 'lastName', 'email', 'userName']}
                    onChange={(event: any, value: any) => handleContactChange(value?.id)}
                    label='Search existing contact'
                    filter={{
                      not: { id: { in: parentEntity.contactPersons?.map((e) => e.contactId) } },
                    }}
                  />
                </Grid>
                {!selectedContactId && (
                  <Fragment>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Typography variant='overline'>OR CREATE NEW</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label='Primary Email'
                        name='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant='outlined'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.emailAddress && errors.emailAddress)}
                        fullWidth
                        helperText={touched.emailAddress && errors.emailAddress}
                        label='Secondary Email'
                        name='emailAddress'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailAddress}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label='First Name'
                        name='firstName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        variant='outlined'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label='Last Name'
                        name='lastName'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        variant='outlined'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.jobTitle && errors.jobTitle)}
                        fullWidth
                        helperText={touched.jobTitle && errors.jobTitle}
                        label='Job Title'
                        name='jobTitle'
                        onChange={handleChange}
                        value={values.jobTitle}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.department && errors.department)}
                        fullWidth
                        helperText={touched.department && errors.department}
                        label='Department'
                        name='department'
                        onChange={handleChange}
                        value={values.department}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectOneAsync
                        name='titleId'
                        entityId={values.titleId || null}
                        entityType='titles'
                        labelFields={['name']}
                        filterFields={['name']}
                        onChange={(event: any, value: any) =>
                          setFieldValue('titleId', value?.id || null)
                        }
                        label='Title'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <KeyboardDatePicker
                        fullWidth
                        error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                        name='dateOfBirth'
                        label='Date of Birth'
                        format='MM/dd/yyyy'
                        value={values.dateOfBirth}
                        onChange={(date, value) => setFieldValue('dateOfBirth', date)}
                        inputVariant='outlined'
                        clearable
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.cellNumber && errors.cellNumber)}
                        fullWidth
                        helperText={touched.cellNumber && errors.cellNumber}
                        label='Cell Number'
                        name='cellNumber'
                        onChange={handleChange}
                        value={values.cellNumber}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.homeNumber && errors.homeNumber)}
                        fullWidth
                        helperText={touched.homeNumber && errors.homeNumber}
                        label='Home Number'
                        name='homeNumber'
                        onChange={handleChange}
                        value={values.homeNumber}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.workNumber && errors.workNumber)}
                        fullWidth
                        helperText={touched.workNumber && errors.workNumber}
                        label='Work Number'
                        name='workNumber'
                        onChange={handleChange}
                        value={values.workNumber}
                        variant='outlined'
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid item xs={12}>
                  <Box sx={{ px: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isPrimary}
                          color='secondary'
                          edge='start'
                          name='isPrimary'
                          onChange={(event) => setFieldValue('isPrimary', event.target.checked)}
                        />
                      }
                      label={
                        <div>
                          <Typography variant='subtitle1'>Is Key Contact?</Typography>
                          <Typography color='textSecondary' variant='caption' component='p'>
                            Indicate whether or not this contact is the primary contact.
                          </Typography>
                        </div>
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={(!selectedContactId && !dirty) || !isValid || isSubmitting}
              >
                Save
              </Button>
              <Button variant='outlined' onClick={handleClose} color='primary'>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ContactPersonAdd;
