import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CourseType } from 'app/enums/CourseType';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { Link as RouterLink } from 'react-router-dom';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { IProject } from 'app/models/responses/IProject';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { IProjectLearnerMilestone } from 'app/models/responses/IProjectLearnerMilestone';
import { ExceptionHandler, Scrollbar } from 'components';
import { rootConfig } from 'config';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { createRef, useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';
import deepCopy from 'utils/deepCopy';
import { DefaultProgress, LearnershipProgress } from './components';
import GetAppIcon from '@material-ui/icons/GetApp';
//@ts-ignore
import Pdf from 'react-to-pdf';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { IProjectLearnerEventAttendance } from 'app/models/entities/IProjectLearnerEventAttendance';
import { v4 as uuid } from 'uuid';
import { IProjectLearnerMilestoneExemption } from 'app/models/responses/IProjectLearnerMilestoneExemption';
import usePrivateAppConfig from 'hooks/usePrivateAppConfig';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  borderlessCell: {
    borderBottom: 'none',
  },
  dividerCell: {
    borderBottom: 'none',
  },
  mainHeader: {
    // backgroundColor: theme.palette.primary.main,
    // '& *': {
    //   color: '#fff'
    // }
  },
  sectionHeader: {
    backgroundColor: theme.palette.background.default,
  },
  actionGrid: {
    marginBottom: theme.spacing(1),
  },
}));

interface IProps {
  project: IProject;
  editable?: boolean;
}

const Progress: React.FC<IProps> = (props) => {
  const { project, editable } = props;

  const pdfRef: any = createRef();
  const classes = useStyles();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [learnershipSections, setLearnershipSections] = useState<ILearnershipRevisionSection[]>([]);
  const [trainingSessions, setTrainingSessions] = useState<IProjectEvent[]>([]);
  const [learners, setLearners] = useState<IProjectLearner[] | null>(null);
  const [milestoneExemptionLookups, setMilestoneExemptionLookups] = useState<
    IProjectLearnerMilestoneExemption[]
  >([]);
  const { privateAppConfig } = usePrivateAppConfig();

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const select = ['id', 'code'];
        const queryString = buildQuery({ select });
        const response = await axios.get(
          `${rootConfig.odataRoute}/projectLearnerMilestoneExemptions${queryString}`,
        );
        if (mounted) {
          setMilestoneExemptionLookups(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const filter = { projectId: project.id, projectEventTypeId: 1 };
        const orderBy = ['start asc'];
        const queryString = buildQuery({ filter, orderBy });
        const response = await axios.get(`${rootConfig.odataRoute}/projectEvents${queryString}`);
        if (mounted) {
          setTrainingSessions(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const filter = { projectId: project.id };
        const expand = ['learner'];
        const queryString = buildQuery({ filter, expand });
        const response = await axios.get(`${rootConfig.odataRoute}/projectLearners${queryString}`);
        if (mounted) {
          setLearners((response.data.value as IProjectLearner[]).sort((a, b) => a.id! - b.id!));
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const expand = { learnershipRevisionUnits: { expand: 'nqfLevel' } };
        const filter = { learnershipRevisionId: project.courseRevisionId };
        const queryString = buildQuery({ expand, filter });
        const response = await axios.get(
          `${rootConfig.odataRoute}/learnershipRevisionSections${queryString}`,
        );
        if (mounted) {
          setLearnershipSections(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  // const getTrainingSessions = useCallback(async () => {
  //   try {
  //     const filter = { projectId: project.id, projectEventTypeId: 1 };
  //     const orderBy = ['start asc'];
  //     const queryString = buildQuery({ filter, orderBy });
  //     const response = await axios.get(`${rootConfig.odataRoute}/projectEvents${queryString}`);
  //     if (mounted.current) {
  //       setTrainingSessions(response.data.value);
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
  //   }
  // }, [mounted, enqueueSnackbar]);

  // const getLearners = useCallback(async () => {
  //   try {
  //     const filter = { projectId: project.id };
  //     const expand = ['learner'];
  //     const queryString = buildQuery({ filter, expand });
  //     const response = await axios.get(`${rootConfig.odataRoute}/projectLearners${queryString}`);
  //     if (mounted.current) {
  //       setLearners(response.data.value);
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
  //   }
  // }, [mounted, enqueueSnackbar]);

  // const getSections = useCallback(async () => {
  //   try {
  //     const expand = { learnershipRevisionUnits: { expand: 'nqfLevel' } };
  //     const filter = { learnershipRevisionId: project.courseRevisionId };
  //     const queryString = buildQuery({ expand, filter });
  //     const response = await axios.get(
  //       `${rootConfig.odataRoute}/learnershipRevisionSections${queryString}`,
  //     );
  //     if (mounted.current) {
  //       setLearnershipSections(response.data.value);
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
  //   }
  // }, [mounted, enqueueSnackbar, project.courseRevisionId]);

  // useEffect(() => {
  //   if (project.courseRevision?.course?.type === CourseType[CourseType.Learnership]) {
  //     getSections();
  //   } else {
  //     setLearnershipSections([]);
  //   }
  //   getTrainingSessions();
  //   getLearners();
  // }, [getSections, getTrainingSessions, getLearners]);

  const handleProjectLearnerTrainingSessionUpdate = (updatedLearners: IProjectLearner[]) => {
    //console.log(learners);
    //setLearners(updatedLearners);
    setLearners(updatedLearners.sort((a, b) => a.id! - b.id!));
    // const updatedLearnerTrainingSessions = [...learnerTrainingSessions!];
    // updatedLearnerTrainingSessions[
    // 	updatedLearnerTrainingSessions.indexOf(oldProjectLearnerTrainingSession)
    // ] = updatedProjectLearnerTrainingSession;
    // //setLearnerTrainingSessions(updatedLearnerTrainingSessions);
    // getLearners();
    // getLearnerMilestones();
  };

  const handleProjectLearnerTrainingSessionCreate = (
    newProjectLearnerTrainingSession: IProjectLearnerEventAttendance,
  ) => {
    // const updatedLearnerTrainingSessions = deepCopy(
    // 	learnerTrainingSessions
    // ) as IProjectLearnerTrainingSession[];
    // updatedLearnerTrainingSessions.push(newProjectLearnerTrainingSession);
    // //setLearnerTrainingSessions(updatedLearnerTrainingSessions);
    // getLearners();
    // getLearnerMilestones();
  };

  const handleLearnersUpdate = (updatedLearners: IProjectLearner[]) => {
    setLearners(updatedLearners.sort((a, b) => a.id! - b.id!));
  };

  const handleProjectLearnerMilestoneUpdate = (updatedLearners: IProjectLearner[]) => {
    setLearners(updatedLearners.sort((a, b) => a.id! - b.id!));
  };

  if (!learners || learners.length <= 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <img
          style={{ width: '100%', maxHeight: 250, marginBottom: 10, marginTop: 30 }}
          src={`${rootConfig.staticFileRoot}/static/illustrations/undraw_progress_indicator_re_4o4n.svg`}
          alt='No Learners'
        />
        <Typography variant='h6'>No enrolled learners to track progress for...</Typography>
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={2} className={classes.actionGrid}>
        {project.courseRevision?.course?.type === CourseType[CourseType.Learnership] &&
          privateAppConfig.learnershipProgressReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.learnershipProgressReport!.name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
        {project.courseRevision?.course?.type === CourseType[CourseType.SkillsProgramme] &&
          privateAppConfig.skillsProgrammeProgressReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.skillsProgrammeProgressReport!
                  .name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
        {project.courseRevision?.course?.type === CourseType[CourseType.ShortCourse] &&
          privateAppConfig.shortCourseProgressReport && (
            <Grid item>
              {' '}
              <Button
                color='secondary'
                variant='outlined'
                size='small'
                startIcon={<GetAppOutlinedIcon fontSize='small' />}
                component={RouterLink}
                to={`/system/reports/viewer/${privateAppConfig.shortCourseProgressReport!.name!}`}
              >
                Export
              </Button>
            </Grid>
          )}
      </Grid>
      <Card>
        <Scrollbar>
          {project.courseRevision?.course?.type === CourseType[CourseType.Learnership] ? (
            <div ref={pdfRef}>
              <LearnershipProgress
                learners={learners}
                onMilestoneSave={handleProjectLearnerMilestoneUpdate}
                project={project}
                sections={learnershipSections}
                trainingSessions={trainingSessions}
                onAttendanceSave={handleProjectLearnerTrainingSessionUpdate}
                onFeedbackSave={handleLearnersUpdate}
                milestoneExemptionLookups={milestoneExemptionLookups}
                editable={editable}
              />
            </div>
          ) : (
            <DefaultProgress
              learners={learners}
              onMilestoneSave={handleProjectLearnerMilestoneUpdate}
              project={project}
              trainingSessions={trainingSessions}
              onAttendanceSave={handleProjectLearnerTrainingSessionUpdate}
              onFeedbackSave={handleLearnersUpdate}
              milestoneExemptionLookups={milestoneExemptionLookups}
              editable={editable}
            />
          )}
        </Scrollbar>
      </Card>
    </>
  );
};

export default Progress;
