import { Paper, Theme, makeStyles } from '@material-ui/core';
import { IEntityBase } from 'app/models/responses/IEntityBase';
import clsx from 'clsx';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import MaterialTable, { Action, Column, Filter, MaterialTableProps, Options } from 'material-table';
import { useSnackbar } from 'notistack';
import buildQuery, { Expand, Filter as ODataFilter } from 'odata-query';
import { CSSProperties, useState } from 'react';
import axios from 'utils/axios';
import deepCopy from 'utils/deepCopy';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  table: {
    '& tfoot tr:last-child td': {
      border: 0,
    },
  },
}));

interface ICustomColumn<T extends IEntityBase> extends Column<T> {
  isBorrowed?: boolean;
}

interface IProps<T extends IEntityBase> extends Omit<MaterialTableProps<T>, 'data'> {
  className?: string;
  title?: string;
  entityType: string;
  appendGetEndpoint?: string;
  option?: Options<T>;
  columns: ICustomColumn<T>[];
  onRowClick?: any;
  actions?: (Action<T> | ((rowData: T) => Action<T>))[];
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  editableOptions?: any;
  detailPanel?: any;
  expand?: Expand<T>;
  filter?: ODataFilter;
  orderBy?: string[];
  style?: CSSProperties;
}

const ODataGrid = <T extends IEntityBase>(props: IProps<T>) => {
  const {
    expand,
    option,
    entityType,
    title,
    columns,
    onRowClick,
    actions,
    canAdd,
    canEdit,
    canDelete,
    detailPanel,
    editableOptions,
    editable: providedEditable,
    className,
    tableRef,
    filter,
    style,
    appendGetEndpoint: providedAppendGetEndpoint,
    orderBy: providedOrderBy,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [appendGetEndpoint] = useState<string>(providedAppendGetEndpoint || '');

  const createEntity = async (newEntity: IEntityBase) => {
    try {
      let entityToCreate: any = {
        ...newEntity,
      };
      columns.forEach((column) => {
        const field = column.field as string;
        const value = column.initialEditValue;
        if (column.hidden && !(field in newEntity)) {
          entityToCreate[field] = value;
        }
      });
      const select = ['id'];
      const queryString = buildQuery({ select });
      // eslint-disable-next-line
      const response = await axios.post(
        `${rootConfig.odataRoute}/${entityType}${queryString}`,
        entityToCreate,
      );
      enqueueSnackbar(`Successfully added record.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const updateEntity = async (updatedEntity: IEntityBase | any, oldEntity: IEntityBase | any) => {
    try {
      const currentEntity = { ...oldEntity };
      delete currentEntity['tableData'];
      const diff = jsonpatch.compare(currentEntity, updatedEntity);
      for (let key in Object.keys(updatedEntity)) {
        if (Array.isArray(key)) {
          if (updatedEntity[key] !== currentEntity[key]) {
            diff.push({
              op: 'replace',
              path: `/${key}`,
              value: updatedEntity[key],
            });
          }
        }
      }

      const select = ['id'];
      const queryString = buildQuery({ select });
      // eslint-disable-next-line
      const response = await axios.patch(
        `${rootConfig.odataRoute}/${entityType}(${updatedEntity.id})${queryString}`,
        diff,
      );
      enqueueSnackbar(`Successfully updated record.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const deleteEntity = async (entityToDelete: IEntityBase) => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${rootConfig.odataRoute}/${entityType}(${entityToDelete.id})`,
      );
      enqueueSnackbar(`Successfully deleted record.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const options: Options<any> = {
    filtering: true,
    // exportButton: true,
    // exportAllData: true,
    emptyRowsWhenPaging: false,
    addRowPosition: 'first',
    pageSizeOptions: [5, 10, 25, 50, 100],
    pageSize: 10,
    search: false,
    columnsButton: true,
    debounceInterval: 500,
    ...option,
  };

  const editable = {
    onRowAdd: canAdd ? (newEntity: any) => createEntity(newEntity) : undefined,
    onRowUpdate: canEdit
      ? (updatedEntity: any, oldEntity: any) => updateEntity(updatedEntity, oldEntity)
      : undefined,
    onRowDelete: canDelete ? (entityToDelete: any) => deleteEntity(entityToDelete) : undefined,
    ...editableOptions,
    ...providedEditable,
  };

  return (
    <div className={clsx(className, classes.root)}>
      <MaterialTable<T>
        tableRef={tableRef}
        style={style}
        columns={columns}
        onRowClick={onRowClick}
        components={{
          Container: (props) => <Paper {...props} className={classes.table} />,
        }}
        actions={actions}
        data={(query) =>
          new Promise((resolve, reject) => {
            let queryFilter: any = deepCopy(filter) || {};
            query.filters.forEach((gridFilter: Filter<any>) => {
              if (gridFilter.column.type === 'boolean') {
                queryFilter[gridFilter.column.field! as any] =
                  gridFilter.value === 'checked' ? true : false;
              } else if (gridFilter.column.type === 'numeric') {
                queryFilter[gridFilter.column.field! as any] = Number(gridFilter.value);
              } else if (gridFilter.column.lookup) {
                if (gridFilter.value?.length <= 0) {
                  return;
                }
                queryFilter[gridFilter.column.field! as any] = { in: gridFilter.value };
              } else if (gridFilter.value) {
                queryFilter[gridFilter.column.field! as any] = {
                  contains: gridFilter.value,
                };
              }
            });
            const top = options.paging === false ? undefined : query.pageSize;
            const skip = options.paging === false ? undefined : query.page * query.pageSize;
            const count = true;
            const select: string[] = columns
              .filter((col: ICustomColumn<T> | any) => col.field && !col.isBorrowed)
              .map((col: ICustomColumn<T> | any) => col.field);
            select.push('id');

            let orderBy = providedOrderBy || [];
            if (query.orderBy) {
              orderBy = [];
              const field = String(query.orderBy.field);
              const orderDirection = String(query.orderDirection);
              orderBy.push(`${field} ${orderDirection}`);
            }
            // odata does not allow ordering of more than 5 elements, limit orderBy to its first 5 elements
            if (orderBy.length > 5) {
              orderBy = orderBy.slice(0, 5);
            }

            const queryString = buildQuery({
              filter: queryFilter,
              count,
              top,
              skip,
              select,
              expand,
              orderBy,
            });
            let url = `${rootConfig.odataRoute}/${entityType}${appendGetEndpoint}${queryString}`;

            axios
              .get(url)
              .then((response) => {
                console.log(response.data);
                resolve({
                  data: response.data.value,
                  page: query.page,
                  totalCount: response.data['@odata.count'],
                });
              })
              .catch((err) =>
                enqueueSnackbar(<ExceptionHandler exception={err} />, {
                  variant: 'error',
                }),
              )
              .then(() =>
                reject({
                  data: [],
                  page: 0,
                  totalCount: 0,
                }),
              );
          })
        }
        options={options}
        editable={editable}
        detailPanel={detailPanel}
        title={title}
      />
    </div>
  );
};

ODataGrid.defaultProps = {
  canAdd: false,
  canEdit: false,
  canDelete: false,
  title: '',
};

export default ODataGrid;
