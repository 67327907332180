import React, { useState, useRef } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import { ExceptionHandler } from 'components';
import { IFileItem } from 'app/models/responses/IFileItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import getFileUrl from 'utils/getFileUrl';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import { ConfirmationModal } from 'components/_dashboard';
import { FileEdit } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fileContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menu: {
    maxWidth: '100%',
    width: 200,
  },
  placeholder: {
    height: 140,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileIcon: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    fontSize: theme.spacing(6),
  },
  displayName: {
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
}));

interface IProps {
  file: IFileItem;
  onDelete: () => void;
  onUpdate: (updatedFile: IFileItem) => void;
}

const fileTypeIcon = (contentType: string, props: any) => {
  if (contentType.includes('image/')) {
    return <ImageIcon {...props} />;
  } else if (contentType.includes('video/')) {
    return <VideocamIcon {...props} />;
  } else {
    return <InsertDriveFileIcon {...props} />;
  }
};

const FileCard: React.FC<IProps> = (props) => {
  const { file, onDelete, onUpdate } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleDownload = async () => {
    try {
      saveAs(getFileUrl(file), file.displayName! + file.extension!);
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const handleDelete = () => {
    setOpenDelete(false);
    onDelete();
  };

  const handleConfirmDelete = () => {
    setOpenDelete(true);
    setOpenMenu(false);
  };

  const handleCardEdit = () => {
    setOpenEdit(true);
    setOpenMenu(false);
  };

  return (
    <Card>
      <div className={classes.placeholder}>
        {fileTypeIcon(file.contentType!, { className: classes.fileIcon })}
      </div>
      <CardContent className={classes.fileContent}>
        <div>
          {file.displayName!.length > 45 ? (
            <Tooltip title={file.displayName!}>
              <Typography color='textPrimary' variant='subtitle2' className={classes.displayName}>
                {file.displayName!.substr(0, 45)}...
              </Typography>
            </Tooltip>
          ) : (
            <Typography color='textPrimary' variant='subtitle2' className={classes.displayName}>
              {file.displayName}
            </Typography>
          )}
          <Typography color='textSecondary' variant='caption'>
            {file.fileType?.name || 'Unknown Type'}
          </Typography>
        </div>
        <div>
          <Tooltip title='Options'>
            <IconButton
              edge='end'
              onClick={() => setOpenMenu(true)}
              ref={moreRef}
              size='small'
              style={{ color: theme.palette.text.secondary }}
            >
              <MoreIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color='primary' fullWidth variant='text' onClick={handleDownload}>
          <Grid container spacing={2} alignItems='center' justifyContent='center'>
            <GetAppIcon fontSize='small' />
            <Grid item>Download</Grid>
          </Grid>
        </Button>
      </CardActions>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        elevation={1}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        PaperProps={{
          className: classes.menu,
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={handleCardEdit}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={handleConfirmDelete}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
      <ConfirmationModal
        title={`Delete File`}
        message={`Are you sure you wish to permanently delete '${file.displayName}'?`}
        onCancel={() => setOpenDelete(false)}
        onConfirm={handleDelete}
        open={openDelete}
        error={true}
      />
      <FileEdit
        onUpdate={onUpdate}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        file={file}
      />
    </Card>
  );
};

export default FileCard;
