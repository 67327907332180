import { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import buildQuery from 'odata-query';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import useMounted from 'hooks/useMounted';
import { Link as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  GenericCommentsEditor,
  GenericDocumentsEditor,
  GenericHeader,
} from 'components/_dashboard';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { EntityContext } from 'app/enums/EntityContext';
import { Details, WorkplaceAttendance, History } from './components';

interface IProps {
  projectLearnerId: number;
  onClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  content: {
    paddingTop: 0,
  },
}));

const tabs = [
  { label: 'Details', value: 'details' },
  { label: 'Workplace Attendance', value: 'workplace-attendance' },
  { label: 'Documents', value: 'documents' },
  { label: 'Comments', value: 'comments' },
  { label: 'History', value: 'history' },
];

const LearnerEdit: React.FC<IProps> = (props) => {
  const { projectLearnerId, onClose, open, onConfirm } = props;
  const mounted = useMounted();
  const classes = useStyles();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [projectLearner, setProjectLearner] = useState<IProjectLearner | null>(null);
  const [currentTab, setCurrentTab] = useState('details');

  const getProjectLearner = useCallback(async () => {
    try {
      const expand = ['learner', 'mentor', 'project'];
      const queryString = buildQuery({ expand });
      const response = await axios.get(
        `${rootConfig.odataRoute}/projectLearners(${projectLearnerId})${queryString}`,
      );
      if (mounted.current) {
        setProjectLearner(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, projectLearnerId, enqueueSnackbar]);

  useEffect(() => {
    getProjectLearner();
  }, [getProjectLearner]);

  const handleClose = () => {
    onClose();
  };

  const handleTabsChange = (event: ChangeEvent<{}>, value: any) => {
    setCurrentTab(value);
  };

  const handleSave = (projectLearner: IProjectLearner) => {
    setProjectLearner(projectLearner);
    onConfirm();
  };

  if (!projectLearner) {
    return <LinearProgress />;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.root }}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle className={classes.root}>
        <GenericHeader
          action={
            <Button
              color='secondary'
              variant='outlined'
              component={RouterLink}
              to={`/training/learners/${projectLearner.learnerId}`}
              startIcon={<AccountCircleIcon />}
            >
              Profile
            </Button>
          }
          title={projectLearner.learner?.fullName!}
          breadCrumbs={[
            { title: projectLearner.project!.name! },
            { title: 'Learners' },
            { title: projectLearner.learner!.idNumber! },
          ]}
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box sx={{ mt: 0 }}>
          <Tabs
            indicatorColor='primary'
            onChange={handleTabsChange}
            scrollButtons='auto'
            textColor='primary'
            value={currentTab}
            variant='scrollable'
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          {currentTab === 'details' && (
            <Details projectLearner={projectLearner} onSave={handleSave} />
          )}
          {currentTab === 'workplace-attendance' && (
            <WorkplaceAttendance projectLearner={projectLearner} />
          )}
          {currentTab === 'documents' && (
            <GenericDocumentsEditor
              contextId={projectLearner.id!}
              context={EntityContext.ProjectLearner}
            />
          )}
          {currentTab === 'comments' && (
            <GenericCommentsEditor
              contextId={projectLearner.id!}
              context={EntityContext.ProjectLearner}
            />
          )}
          {currentTab === 'history' && <History projectLearner={projectLearner} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LearnerEdit;
